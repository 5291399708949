import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 672.000000 795.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,795.000000) scale(0.100000,-0.100000)">
<path d="M6670 7155 c0 -485 4 -795 9 -795 5 0 13 -9 16 -20 3 -11 11 -20 16
-20 5 0 9 318 9 815 l0 815 -25 0 -25 0 0 -795z"/>
<path d="M3803 7034 c-10 -4 -13 -255 -13 -1202 l0 -1197 49 -28 c77 -43 199
-138 273 -214 206 -207 289 -413 316 -773 l7 -95 92 -36 c50 -20 138 -49 195
-65 89 -25 121 -28 238 -29 74 0 147 3 163 8 l27 7 0 1374 0 1374 -96 158
c-119 199 -208 316 -321 425 -105 101 -205 169 -316 216 -142 60 -221 75 -422
79 -99 2 -186 1 -192 -2z"/>
<path d="M2200 6311 c-354 -79 -605 -277 -706 -557 -29 -80 -30 -114 -3 -148
16 -21 29 -26 63 -26 42 0 42 0 75 68 49 99 97 168 176 251 108 114 233 190
382 233 105 31 342 33 443 4 82 -23 230 -92 299 -138 32 -22 60 -34 72 -31 31
8 69 61 69 96 0 69 -186 190 -365 238 -116 31 -385 36 -505 10z"/>
<path d="M6692 6294 c1 -9 9 -19 16 -22 9 -3 13 2 10 14 -1 9 -9 19 -16 22 -9
3 -13 -2 -10 -14z"/>
<path d="M6671 6184 c1 -65 7 -100 21 -131 11 -24 21 -43 23 -43 2 0 4 56 4
125 0 69 -3 125 -8 125 -5 0 -17 3 -25 6 -14 6 -16 -5 -15 -82z"/>
<path d="M2277 6040 c-265 -59 -483 -247 -559 -481 -20 -64 -21 -69 -4 -94 19
-29 53 -45 95 -45 23 0 31 7 42 33 27 65 131 207 194 265 36 33 96 76 133 95
65 34 189 67 252 67 101 0 309 -75 399 -144 44 -33 53 -33 90 5 51 50 44 84
-31 156 -108 102 -239 152 -418 159 -79 3 -127 -1 -193 -16z"/>
<path d="M6670 5940 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6700 5919 c0 -15 -5 -19 -15 -15 -8 3 -15 1 -15 -4 0 -6 11 -10 25
-10 20 0 25 5 25 25 0 14 -4 25 -10 25 -5 0 -10 -9 -10 -21z"/>
<path d="M6670 5829 c0 -16 9 -33 23 -43 l22 -17 -22 6 -23 6 0 -569 0 -570
25 -16 25 -17 -1 623 c-1 343 -4 615 -6 606 -2 -10 -8 -18 -13 -18 -5 0 -14 8
-19 18 -9 15 -10 13 -11 -9z"/>
<path d="M2314 5731 c-182 -52 -330 -187 -381 -348 -16 -52 -16 -54 3 -74 11
-11 33 -21 49 -22 26 -2 34 5 74 72 94 157 219 245 363 257 111 10 186 -13
335 -102 l32 -19 25 24 c38 36 33 65 -19 115 -81 80 -187 116 -330 115 -54 0
-114 -7 -151 -18z"/>
<path d="M2335 5492 c-52 -33 -68 -59 -73 -115 -3 -42 1 -58 20 -87 32 -48 82
-72 137 -67 134 14 181 181 73 261 -37 28 -119 32 -157 8z"/>
<path d="M3030 5468 c-58 -4 -139 -14 -180 -21 l-75 -13 -3 -738 -2 -738 -41
-47 c-105 -119 -145 -296 -104 -455 38 -144 155 -307 278 -387 309 -201 737
-32 863 341 14 40 19 84 19 160 0 240 -109 416 -320 515 -176 83 -340 86 -538
10 -16 -7 -17 11 -15 321 l3 327 60 15 c41 11 114 15 235 16 187 0 261 -12
413 -67 37 -14 69 -22 71 -18 2 3 1 170 -3 371 l-6 365 -68 17 c-146 35 -365
45 -587 26z"/>
<path d="M2063 5053 c-637 -725 -803 -1616 -438 -2351 241 -484 651 -826 1155
-960 146 -39 219 -47 465 -47 256 0 342 10 525 60 441 122 820 418 1087 848
99 161 258 511 279 616 l6 28 -139 6 c-145 6 -285 31 -483 86 -57 16 -106 28
-107 27 -2 -2 -11 -34 -22 -72 -58 -219 -218 -465 -409 -629 -291 -251 -695
-352 -1045 -260 -507 132 -770 449 -841 1013 -25 193 -21 544 10 927 24 307
43 785 31 785 -3 0 -37 -35 -74 -77z"/>
<path d="M6670 4150 c0 -385 2 -444 16 -465 9 -13 13 -33 10 -43 -4 -11 0 -24
9 -32 13 -11 15 39 15 470 0 448 -1 484 -17 496 -10 7 -21 14 -25 14 -5 0 -8
-198 -8 -440z"/>
<path d="M6672 3314 c2 -142 7 -268 12 -278 7 -14 6 -17 -2 -12 -9 5 -12 -59
-12 -263 l0 -271 25 0 25 0 0 523 c0 320 -4 528 -10 537 -5 8 -10 11 -10 6 0
-5 -7 -3 -15 4 -14 12 -15 -14 -13 -246z"/>
<path d="M6670 2321 c0 -5 6 -11 13 -14 9 -3 9 -7 -1 -19 -18 -22 -18 -2164 1
-2171 9 -4 9 -6 0 -6 -9 -1 -13 -19 -13 -56 0 -52 1 -55 25 -55 l25 0 0 1151
c0 984 -2 1152 -14 1165 -16 16 -36 19 -36 5z m37 -2267 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M1314 1366 c-17 -8 -41 -27 -52 -44 -49 -68 -55 -238 -13 -326 30
-62 65 -79 147 -74 90 6 117 34 139 146 l7 33 -53 -3 c-52 -3 -53 -4 -60 -38
-7 -31 -31 -60 -51 -60 -39 0 -67 103 -54 202 10 70 21 88 56 88 31 0 50 -22
50 -59 0 -19 6 -21 56 -21 48 0 55 2 50 18 -2 9 -7 31 -11 47 -17 83 -124 129
-211 91z"/>
<path d="M1650 1362 c-56 -29 -83 -94 -84 -200 -1 -112 17 -173 61 -212 29
-26 41 -30 94 -30 96 0 139 39 155 140 l7 40 -50 0 c-49 0 -50 0 -62 -40 -14
-46 -41 -66 -70 -50 -25 13 -35 57 -34 153 2 89 19 127 57 127 27 0 41 -14 50
-52 6 -26 10 -28 57 -28 l51 0 -7 38 c-20 110 -128 165 -225 114z"/>
<path d="M3845 1365 c-79 -28 -111 -107 -102 -250 8 -143 61 -202 174 -193 63
6 104 37 122 97 21 73 17 81 -39 81 -48 0 -50 -1 -50 -29 0 -44 -30 -76 -64
-67 -38 9 -48 45 -44 159 3 90 5 101 27 118 13 10 27 19 32 19 18 0 49 -41 49
-65 0 -23 3 -25 50 -25 45 0 50 2 50 23 -1 39 -37 105 -67 120 -45 24 -94 28
-138 12z"/>
<path d="M4174 1361 c-64 -29 -89 -89 -89 -211 1 -156 50 -230 155 -230 106 0
153 61 159 209 5 148 -26 217 -110 241 -48 14 -68 12 -115 -9z m110 -94 c18
-24 21 -42 21 -115 -1 -102 -17 -145 -56 -150 -43 -5 -63 29 -67 114 -5 92 3
139 29 165 26 26 46 21 73 -14z"/>
<path d="M4893 1369 c-71 -21 -108 -115 -101 -252 7 -138 54 -197 158 -197 81
0 132 48 146 139 l7 41 -50 0 -50 0 -7 -39 c-7 -47 -27 -65 -61 -57 -35 9 -45
41 -45 150 0 79 3 97 21 120 11 14 27 26 35 26 22 0 54 -38 54 -65 0 -23 3
-25 50 -25 43 0 50 3 50 20 0 35 -32 100 -59 119 -32 23 -105 33 -148 20z"/>
<path d="M597 1363 c-4 -3 -7 -102 -7 -220 l0 -213 121 0 c120 0 121 0 150 29
25 26 29 37 29 86 0 55 -12 93 -34 107 -7 5 -5 16 7 37 33 56 17 148 -30 170
-26 12 -225 15 -236 4z m181 -121 c3 -26 -1 -34 -19 -40 -12 -5 -33 -7 -46 -4
-19 4 -23 10 -23 44 l0 39 43 -3 c39 -3 42 -5 45 -36z m7 -177 l0 -40 -47 -3
-48 -3 0 46 0 46 48 -3 47 -3 0 -40z"/>
<path d="M940 1150 l0 -221 128 3 127 3 0 40 0 40 -80 5 -80 5 -3 38 -3 37 76
0 76 0 -3 48 -3 47 -73 3 -73 3 3 37 3 37 78 3 77 3 0 44 0 45 -125 0 -125 0
0 -220z"/>
<path d="M1941 1158 c-28 -117 -51 -216 -51 -220 0 -5 22 -8 49 -8 48 0 49 1
55 36 l7 35 52 -3 c49 -3 52 -5 57 -33 5 -28 8 -30 58 -33 l53 -3 -8 28 c-3
15 -27 114 -53 218 l-45 190 -61 3 -61 3 -52 -213z m135 -14 l6 -54 -31 0
c-37 0 -38 8 -5 140 l7 25 8 -29 c4 -15 11 -52 15 -82z"/>
<path d="M3119 1362 l-107 -3 -4 -29 c-6 -40 2 -49 43 -54 19 -2 37 -4 42 -5
4 0 7 -78 7 -172 l0 -170 43 3 42 3 3 168 c2 165 3 167 25 168 12 1 27 2 32 4
6 1 16 3 23 3 18 3 16 82 -2 82 -8 0 -20 1 -28 3 -7 1 -61 1 -119 -1z"/>
<path d="M3408 1362 c-15 -2 -29 -11 -32 -20 -10 -34 -96 -395 -96 -404 0 -5
21 -8 47 -6 44 3 48 5 53 33 5 29 7 30 59 30 53 0 55 -1 63 -32 8 -31 11 -33
53 -33 25 0 45 4 45 9 0 15 -98 412 -102 417 -7 6 -59 10 -90 6z m61 -249 c1
-19 -4 -23 -29 -23 -22 0 -30 5 -30 18 0 26 21 135 28 147 6 10 30 -100 31
-142z"/>
<path d="M4467 1362 c-15 -3 -17 -23 -17 -218 l0 -214 40 0 40 0 0 118 c0 64
4 123 9 130 5 9 17 -13 36 -65 15 -43 36 -102 47 -130 l19 -53 50 0 49 0 0
214 0 214 -40 4 -40 4 0 -134 c0 -73 -4 -131 -8 -128 -4 3 -28 61 -51 129
l-44 125 -36 4 c-20 2 -44 2 -54 0z"/>
<path d="M5154 1353 c-13 -13 -19 -356 -7 -401 5 -21 10 -22 130 -22 l124 0
-3 43 -3 42 -77 3 -78 3 0 44 0 45 76 0 75 0 -3 38 -3 37 -72 3 -73 3 0 44 0
45 80 0 80 0 0 40 c0 25 -5 40 -12 40 -168 5 -223 4 -234 -7z"/>
<path d="M5456 1362 c-14 -3 -16 -28 -16 -218 l0 -214 45 0 44 0 3 78 3 77 68
5 c59 4 72 9 92 32 42 48 44 162 5 210 -16 21 -30 24 -123 28 -57 3 -112 4
-121 2z m168 -98 c11 -28 6 -71 -8 -83 -8 -7 -30 -11 -48 -9 -30 3 -33 6 -36
42 -2 21 -1 44 2 52 8 20 82 19 90 -2z"/>
<path d="M5742 1320 l3 -45 40 0 40 0 5 -170 5 -170 45 0 45 0 5 170 5 170 40
0 c40 0 40 0 43 43 3 38 1 42 -20 43 -13 0 -76 1 -141 2 l-118 2 3 -45z"/>
<path d="M2370 1353 c-1 -21 -2 -391 -1 -407 1 -17 9 -18 111 -14 103 3 112 5
136 29 69 69 71 304 4 371 -28 27 -31 28 -139 28 -61 0 -111 -3 -111 -7z m188
-103 c8 -17 12 -55 10 -113 -3 -79 -6 -91 -27 -108 -13 -10 -36 -19 -52 -19
l-29 0 0 136 0 135 42 -3 c33 -2 45 -8 56 -28z"/>
<path d="M2734 1150 c-28 -115 -48 -212 -46 -215 3 -3 24 -5 48 -5 40 0 42 2
48 35 7 35 7 35 60 35 54 0 54 0 60 -36 7 -35 7 -35 54 -32 26 2 46 8 46 13
-1 6 -23 101 -50 213 l-48 202 -61 0 -61 0 -50 -210z m126 53 c6 -32 13 -70
16 -85 5 -26 4 -28 -31 -28 -35 0 -36 2 -31 28 22 117 28 142 31 142 2 0 9
-26 15 -57z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
